import { Injectable } from '@angular/core';
import {HttpParameterCodec} from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class HttpUrlEncodingCodec implements HttpParameterCodec {
    encodeKey(k: string): string { return standardEncoding(k); }
    encodeValue(v: string): string { return standardEncoding(v); }
    decodeKey(k: string): string { return decodeURIComponent(k); }
    decodeValue(v: string) { return decodeURIComponent(v); }
}
function standardEncoding(v: string): string {
  if(v && v !== ''){
     v = v.replace("+", "%2B"); //TODO its an angular bug with encoded + is replaced with space so we are manually encoding
  }
  return encodeURIComponent(v);
}
