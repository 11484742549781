import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SysService } from '../services/sys.service';
import { AppConfig } from 'src/app/_config';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    public sysService: SysService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.sysService.isLoggedIn() !== true) {
      this.sysService.error('Access Denied, Login is Required to Access This Page!');
      this.sysService.navigate([AppConfig.login], false, {
        queryParams: { returnUrl: state.url }
      });
      //return false;
    }
    return true;
  }

}
