import { Component } from '@angular/core';
import { Collapse, Dropdown, Offcanvas, Popover, ScrollSpy, Tab, Tooltip } from 'bootstrap';
import Modal from 'bootstrap/js/dist/modal';
import Toast from 'bootstrap/js/dist/toast';
import { ConfirmationService } from 'primeng/api';
import { UserService } from './_common/services/user.service';
import { SysService } from './_common/services/sys.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {




  ngOnInit(): void {


  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }
  title = 'mamors-admin.ng';

  constructor(public userService: UserService, private router: Router, public syService: SysService) {
    Array.from(document.querySelectorAll('.toast')).forEach(toastEl => new Toast(toastEl));
    Array.from(document.querySelectorAll('[data-bs-toggle="modal"]')).forEach(modalNode => new Modal(modalNode));
    Array.from(document.querySelectorAll('[data-bs-toggle="dropdown"]')).forEach(toastNode => new Dropdown(toastNode));
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    Array.from(document.querySelectorAll('[data-bs-toggle="offcanvas"]')).forEach(offCanvas => new Offcanvas(offCanvas));
    Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
    Array.from(document.querySelectorAll('[data-bs-toggle="tab"]')).forEach((tabNode: string | Element) => new Tab(tabNode));
    Array.from(document.querySelectorAll('[data-bs-toggle="collapse"]')).forEach(collapseNode => new Collapse(collapseNode));
    Array.from(document.querySelectorAll('[data-bs-smooth-scroll="true"]')).forEach(scrollspyNode => new ScrollSpy(scrollspyNode));
  }

}
