<div class="page-wrapper dashboard">
  <div class="content">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="dash-widget dash-widget-hospital">
          <span></span>
          <!-- <span class="dash-widget-bg"><i class="i i-hospital-o" aria-hidden="true"></i></span> -->
          <div class="dash-widget-info">
            <span class="dash-widget-bg">04</span>
            <p class="count">Hospitals</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="dash-widget medicine dash-widget-medicine">
          <span></span>
          <!-- <span class="dash-widget-bg"><i class="i i-vial" aria-hidden="true"></i></span> -->
          <div class="dash-widget-info">
            <span class="dash-widget-bg">22</span>
            <p class="count">Tests</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="dash-widget patient dash-widget-patient">
          <span></span>
          <!-- <span class="dash-widget-bg"><i class="i i-person"></i></span> -->
          <div class="dash-widget-info">
            <span class="dash-widget-bg">10</span>
            <p class="count">Patients</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="dash-widget stroke dash-widget-report">
          <span class="dash-widget-bg"><i class="i i-favorite_outline" aria-hidden="true"></i></span>
          <div class="dash-widget-info">
            <p class="count">03</p>
            <span class="widget-title stroke">Stroke Education </span>
          </div>
        </div>
      </div> -->
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-6">
        <div class="dash-widget report dash-widget-report">
          <span></span>
          <!-- <span class="dash-widget-bg"><i class="i i-file-prescription" aria-hidden="true"></i></span> -->
          <div class="dash-widget-info">
            <span class="dash-widget-bg">05</span>
            <p class="count">Reports</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-24 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="chart-title">
              <h4>Regular medicine intakes/taken</h4>
              <span><i class="i i-arrow_drop_up" aria-hidden="true"></i> 15% Higher than Last
                Month</span>
            </div>
            <canvas id="linegraph"></canvas>
          </div>
        </div>
      </div>
      <div class="col-24 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="chart-title">
              <h4>Review visits or visits - Govt hospital and Pvt hospital</h4>
              <div>
                <ul class="chat-user-total">
                  <li><i class="i i-circle" aria-hidden="true"></i>Govt</li>
                  <li><i class="i i-trip_origin" aria-hidden="true"></i> Private</li>
                </ul>
              </div>
            </div>
            <canvas id="bargraph"></canvas>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-24 col-md-12 col-lg-12 col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="chart-title">
              <h4>Adherence Levels by Division</h4>
            </div>
            <canvas id="barsgraph"></canvas>
          </div>
        </div>
      </div>

      <div class="col-24 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="chart-title">
              <h4>Scale</h4>
            </div>
            <canvas id="salesLineGraph"></canvas>
          </div>
        </div>
      </div>
      <div class="col-24 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="chart-title">
              <h4>Hospitals</h4>
            </div>
            <canvas id="salesPieChart"></canvas>
          </div>
        </div>
      </div>
      <br>

    </div>

    <!-- <table>
      <tr>
        <td>
          <canvas id="lineChart"></canvas>
        </td>
        <td style="width :700px; height: 50%;">
          <canvas id="barChart"></canvas>
        </td>
      </tr>
    </table> -->
    <br>
    <!-- <div class="row">
      <div class="col-24 col-md-12 col-lg-16 col-xl-16">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title d-inline-block">Upcoming Appointments</h4>
            <div class="actions">
              <a href="appointments.html" class="btn btn-primary">View all</a>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead class="d-none">
                  <tr>
                    <th>Patient Name</th>
                    <th>Doctor Name</th>
                    <th>Timing</th>
                    <th class="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="min-width: 200px;">
                      <a class="avatar" href="profile.html">A</a>
                      <h2><a href="profile.html">Ajay Alyn <span>Kozhikode,Kerala</span></a></h2>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Appointment With</h5>
                      <p>Dr. Cristina</p>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Timing</h5>
                      <p>7.30 PM</p>
                    </td>
                    <td class="text-right">
                      <a href="appointments.html" class="btn btn-outline-primary take-btn">Take up</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="min-width: 200px;">
                      <a class="avatar" href="profile.html">T</a>
                      <h2><a href="profile.html">Tara Anjith <span>Trivandrum, Kerala</span></a></h2>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Appointment With</h5>
                      <p>Dr. Priyanka Varghese</p>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Timing</h5>
                      <p>7.40 PM</p>
                    </td>
                    <td class="text-right">
                      <a href="appointments.html" class="btn btn-outline-primary take-btn">Take up</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="min-width: 200px;">
                      <a class="avatar" href="profile.html">N</a>
                      <h2><a href="profile.html">Nikhil Soman <span>Palakkad,Kerala</span></a></h2>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Appointment With</h5>
                      <p>Dr. Arjun Ramesh</p>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Timing</h5>
                      <p>7.50 PM</p>
                    </td>
                    <td class="text-right">
                      <a href="appointments.html" class="btn btn-outline-primary take-btn">Take up</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="min-width: 200px;">
                      <a class="avatar" href="profile.html">S</a>
                      <h2><a href="profile.html">Sithara <span>Trivandrum, Kerala</span></a></h2>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Appointment With</h5>
                      <p>Dr. Cristina</p>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Timing</h5>
                      <p>8.10 PM</p>
                    </td>
                    <td class="text-right">
                      <a href="appointments.html" class="btn btn-outline-primary take-btn">Take up</a>
                    </td>
                  </tr>
                  <tr>
                    <td style="min-width: 200px;">
                      <a class="avatar" href="profile.html">M</a>
                      <h2><a href="profile.html">Manoj K <span>Kannur, Kerala</span></a></h2>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Appointment With</h5>
                      <p>Dr. Sajin</p>
                    </td>
                    <td>
                      <h5 class="time-title p-0">Timing</h5>
                      <p>8.15 PM</p>
                    </td>
                    <td class="text-right">
                      <a href="appointments.html" class="btn btn-outline-primary take-btn">Take up</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-24 col-md-12 col-lg-8 col-xl-8">
        <div class="card member-panel">
          <div class="card-header bg-white">
            <h4 class="card-title mb-0">Doctors</h4>
          </div>
          <div class="card-body">
            <ul class="contact-list">
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Cristina"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status online"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Cristina</span>
                    <span class="contact-date">MBBS, MD</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Priyanka Varghese"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status offline"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Priyanka Varghese</span>
                    <span class="contact-date">MD</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Arjun Ramesh"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status away"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Arjun Ramesh</span>
                    <span class="contact-date">BMBS</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Sajin"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status online"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Sajin</span>
                    <span class="contact-date">MS, MD</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Akhila"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status offline"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Akhila</span>
                    <span class="contact-date">MBBS</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="contact-cont">
                  <div class="user-img">
                    <a href="profile.html" title="Thomas Issac"><img src="assets/images/user.jpg" alt=""
                        class="w-40 rounded-circle"><span class="status away"></span></a>
                  </div>
                  <div class="contact-info">
                    <span class="contact-name text-ellipsis">Thomas Issac</span>
                    <span class="contact-date">MBBS, MD</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-footer text-center bg-white">
            <a href="doctors.html" class="text-muted">View all Doctors</a>
          </div>
        </div>
      </div>
    </div>
    <br> -->
    <!-- <div class="row">
      <div class="col-24 col-md-12 col-lg-16 col-xl-16">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title d-inline-block">New Patients </h4>
            <div class="actions">
              <a href="patients.html" class="btn btn-primary">View all</a>
            </div>
          </div>
          <div class="card-block">
            <div class="table-responsive">
              <table class="table mb-0 new-patient-table">
                <tbody>
                  <tr>
                    <td>
                      <img width="28" height="28" class="rounded-circle" src="assets/images/user.jpg" alt="">
                      <h2>Kishore</h2>
                    </td>
                    <td>kishore21@gmail.com</td>
                    <td>+91-9090564561</td>
                    <td><button class="btn btn-primary btnPrimaryBtn">Stroke</button></td>
                  </tr>
                  <tr>
                    <td>
                      <img width="28" height="28" class="rounded-circle" src="assets/images/user.jpg" alt="">
                      <h2>Yaswanth</h2>
                    </td>
                    <td>yaswanth@yahoo.com</td>
                    <td>+91- 8787450938</td>
                    <td><button class="btn btn-primary btnPrimaryBtn brdrInfo">Stroke</button></td>
                  </tr>
                  <tr>
                    <td>
                      <img width="28" height="28" class="rounded-circle" src="assets/images/user.jpg" alt="">
                      <h2>Naina</h2>
                    </td>
                    <td>naina2@yahoo.com</td>
                    <td>+91- 7898098765</td>
                    <td><button class="btn btn-primary btnPrimaryBtn brdrSuccess">Stroke</button></td>
                  </tr>
                  <tr>
                    <td>
                      <img width="28" height="28" class="rounded-circle" src="assets/images/user.jpg" alt="">
                      <h2>Vijeesha</h2>
                    </td>
                    <td>viji@yahoo.com</td>
                    <td>+91- 9209877890</td>
                    <td><button class="btn btn-primary btnPrimaryBtn">Fever</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-24 col-md-12 col-lg-8 col-xl-8">
        <div class="hospital-barchart">
          <h4 class="card-title d-inline-block">Hospital Management</h4>
        </div>
        <div class="bar-chart">
          <div class="legend">
            <div class="item">
              <h4>Level1</h4>
            </div>

            <div class="item">
              <h4>Level2</h4>
            </div>
            <div class="item text-right">
              <h4>Level3</h4>
            </div>
            <div class="item text-right">
              <h4>Level4</h4>
            </div>
          </div>
          <div class="chart clearfix">
            <div class="item">
              <div class="bar">
                <span class="percent">16%</span>
                <div class="item-progress" data-percent="16" style="--bar-percentage:16%">
                  <span class="title">Scale Patient</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="bar">
                <span class="percent">71%</span>
                <div class="item-progress" data-percent="71" style="--bar-percentage:71%">
                  <span class="title">Medicines</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="bar">
                <span class="percent">82%</span>
                <div class="item-progress" data-percent="82" style="--bar-percentage:82%">
                  <span class="title">Diagnostic Test</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="bar">
                <span class="percent">67%</span>
                <div class="item-progress" data-percent="67" style="--bar-percentage:67%">
                  <span class="title">New Patients</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="bar">
                <span class="percent">30%</span>
                <div class="item-progress" data-percent="30" style="--bar-percentage:30%">
                  <span class="title">Appointment</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <div class="notification-box">
    <div class="msg-sidebar notifications msg-noti">
      <div class="topnav-dropdown-header">
        <span>Messages</span>
      </div>
      <div class="drop-scroll msg-list-scroll" id="msg_list">
        <ul class="list-box">
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">R</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Richard Miles </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item new-message">
                <div class="list-left">
                  <span class="avatar">J</span>
                </div>
                <div class="list-body">
                  <span class="message-author">John Doe</span>
                  <span class="message-time">1 Aug</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">T</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Tarah Shropshire </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">M</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Mike Litorus</span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">C</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Catherine Manseau </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">D</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Domenic Houston </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">B</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Buster Wigton </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">R</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Rolland Webber </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">C</span>
                </div>
                <div class="list-body">
                  <span class="message-author"> Claire Mapes </span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">M</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Melita Faucher</span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">J</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Jeffery Lalor</span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">L</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Loren Gatlin</span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="chat.html">
              <div class="list-item">
                <div class="list-left">
                  <span class="avatar">T</span>
                </div>
                <div class="list-body">
                  <span class="message-author">Tarah Shropshire</span>
                  <span class="message-time">12:28 AM</span>
                  <div class="clearfix"></div>
                  <span class="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="topnav-dropdown-footer">
        <a href="chat.html">See all messages</a>
      </div>
    </div>
  </div>
</div>