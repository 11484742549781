import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppHeader } from '../../_config';
import { Model } from '../components/model.view';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  httpParams: HttpParams;

  returnUrl = '/';
  isLoginPage = signal(true);
  private loginTimestampKey = 'loginTimestamp';

  constructor(
    private http: HttpClient) {
    this.httpParams = new HttpParams({ encoder: new HttpUrlEncodingCodec() });
  }

  private getBody(email: string, password: string, timeZone: string) {
    if (environment.backend.spring)
      return this.httpParams.set("email", email).set("password", password).set("timeZone", timeZone).set("grant_type", "password");
    else if (environment.backend.node || environment.backend.php)
      return { email, password };
    return null;
  }

  login(email: string, password: string, timeZone: string): Observable<any> {
    return this.http.post(environment.auth.login, this.getBody(email, password, timeZone), { headers: AppHeader.getLoginHeader() });
  }

  register(model: Model, contentType = 'application/json') {
    return this.http.post(environment.auth.register, model.form.value, { headers: AppHeader.getLoginHeader() });
  }

  isUserLoggedIn(): boolean {
    return localStorage.getItem('auth-token') !== null;
  }

  // Store login timestamp when user logs in
  storeLoginTimestamp() {
    localStorage.setItem(this.loginTimestampKey, new Date().toISOString());
  }

  // Get login timestamp from local storage
  getLoginTimestamp(): Date | null {
    const timestamp = localStorage.getItem(this.loginTimestampKey);
    return timestamp ? new Date(timestamp) : null;
  }

  // Check if 8 hours have passed
  hasTokenExpired(): boolean {
    const loginTime = this.getLoginTimestamp();
    if (!loginTime) return false;

    const currentTime = new Date();
    const diffInMs = currentTime.getTime() - loginTime.getTime();
    const diffInHours = diffInMs / (1000 * 60 * 60); // Convert ms to hours
    return diffInHours >= 8;
  }


  // refreshToken(): Observable<any> {
  //   const headers = new HttpHeaders({
  //     Authorization: `Bearer ${this.getToken()}`,
  //   });

  //   return this.http.post('/refresh-token', {}, { headers });
  // }


  //  register(email: string, email: string, password: string): Observable<any> {
  //    return this.http.post(environment.auth.register, {
  //      email,
  //      email,
  //      password
  //    },  { headers: AppHeader.getLoginHeader() });
  //  }
}

