import { Injectable } from '@angular/core';

import { SysService } from './sys.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppConstants } from 'src/app/_config';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private permissions: string[] = [];



  // logout() {
  //   throw new Error('Method not implemented.');
  // }


  private loggedIn = new BehaviorSubject<boolean>(this.checkInitialLoginStatus());
  static isLoggedIn: any;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  // async login(data: any, route: ActivatedRoute, sysService: SysService) {
  //   await this.saveToken(environment.backend.spring ? data.access_token : data.token); // TODO set the right token key
  //   await this.saveUser(data.user);
  //   const returnUrl = route.snapshot.queryParams['returnUrl'] || '/';
  //   sysService.appInit();
  //   sysService.navigate([returnUrl]);
  // }


  isLoggedIn$ = this.loggedIn.asObservable();

  // Set login status
  setLoginStatus(status: boolean) {
    this.loggedIn.next(status);
  }



  // Simulate a method to check if user is initially logged in
  private checkInitialLoginStatus(): boolean {
    return !!this.getUser(); // Assuming getUser checks for a logged-in user
  }


  async login(
    data: any,
    route: ActivatedRoute,
    sysService: SysService,
  ) {
    this.saveToken(data.token);
    this.saveUser(data);
    const returnUrl = route.snapshot.queryParams['returnUrl'] || '/users';
    sysService.appInit();
    sysService.navigate([returnUrl]);
  }

  loginCredential(data: Partial<{ email: string | null; password: string | null; }>): Observable<any> {
    const { email, password } = data;
    this.authService.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/users';
    const url = `${environment.auth.login}`;
    return this.http.post(url, data)
  }


  async changeSite(data: any, route: ActivatedRoute, sysService: SysService) {
    await this.saveToken(environment.backend.spring ? data.access_token : data.token); // TODO set the right token key
    const user = this.getUser();
    if (user) {
      if (environment.backend.spring)
        user.access_token = data.access_token;
      else
        user.token = data.token;
      await this.saveUser(user);
    }
    const returnUrl = route.snapshot.queryParams['returnUrl'] || '/';
    sysService.navigate([returnUrl]);
  }

  signOut(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  saveToken(token: string): void {
    window.localStorage.removeItem(AppConstants.tokenKey);
    window.localStorage.setItem(AppConstants.tokenKey, token);
  }

  getToken(): string | null {
    return window.localStorage.getItem(AppConstants.tokenKey);
  }

  saveUser(user: any): void {
    window.localStorage.removeItem(AppConstants.userKey);
    window.localStorage.setItem(AppConstants.userKey, JSON.stringify(user));
  }

  getUser(): any {
    const user = window.localStorage.getItem(AppConstants.userKey);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  isLoggedIn(): boolean {
    return (this.getUser() !== null);
  }


  isAdmin(): any {
    if (this.isLoggedIn()) {
      const user = this.getUser();
      return (user.role_id === 1)
    }
    return false;
  }


  isCoordinator(): any {
    if (this.isLoggedIn()) {
      const user = this.getUser();
      return (user.role_id === 3)
    }
    return false;
  }

  logout(): void {
    this.setLoginStatus(false);
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  // isLoggedIn(): boolean {
  //   // Assuming you store the token in localStorage, check if token exists
  //   return !!localStorage.getItem('token');
  // }

  // hasPermission(key: any = [], user: { permissions: string | any[]; }): boolean {
  //   if (user && user.permissions) {
  //     let v = false;
  //     for (let i = 0; i < key.length; i++) {
  //       v = user.permissions.includes(key[i]);
  //       if (v) {
  //         break;
  //       }
  //     }
  //     return v;
  //   }
  //   return true;
  // }

  // Method to store permissions after login
  setPermissions(permissions: string[]) {
    this.permissions = permissions;
    window.localStorage.setItem('userPermissions', JSON.stringify(this.permissions));
  }

  // Method to retrieve permissions
  getPermissions(): string[] {
    const savedPermissions = window.localStorage.getItem('userPermissions');
    if (savedPermissions) {
      this.permissions = JSON.parse(savedPermissions);
    }
    return this.permissions;
  }

  // Method to check if a user has a specific permission
  hasPermission(permission: string | null): boolean {
    // If no permission is provided, default to true (no restriction)
    if (!permission) return true;

    // If permissions in memory are empty, attempt to load from localStorage
    if (this.permissions.length === 0) {
      const savedPermissions = window.localStorage.getItem('userPermissions');
      if (savedPermissions) {
        this.permissions = JSON.parse(savedPermissions);
      }
    }

    // Check if the required permission exists in the user's permissions
    return this.permissions.includes(permission);
  }



  scrollTo(top = 0, left = 0): void {
    document.querySelector('body')?.scroll({
      top: top,
      left: left,
      behavior: 'smooth'
    });
  }

}
