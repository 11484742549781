<div class="sidebar" id="sidebar">
  <div class="sidebar-inner slimscroll">
    <div id="sidebar-menu" class="sidebar-menu" (mouseover)="revealMenu()" (mouseleave)="collapseMenu()">
      <ul class="accordion" id="mainSidemenu">

        <li *ngFor="let menu of filteredMenuItems" [class.has-submenu]="menu.submenu"
          [class.open]="isSubmenuOpen(menu.id)">
          <a [routerLink]="menu.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            (click)="toggleSubmenu(menu.id)">
            <i [class]="'i i-' + menu.icon"></i>
            <span>{{ menu.label }}</span>
            <i *ngIf="menu.submenu" class="submenu-toggle"></i>
          </a>
          <!-- Submenu -->
          <ul *ngIf="menu.submenu && isSubmenuOpen(menu.id)" class="submenu">
            <li *ngFor="let sub of menu.submenu">
              <a [routerLink]="sub.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                {{ sub.label }}
              </a>
            </li>
          </ul>
        </li>

      </ul>
    </div>
  </div>
</div>


<div class="sidebar-overlay"></div>






<!-- 
<div class="sidebar" id="sidebar">
  <div class="sidebar-inner slimscroll">
    <div id="sidebar-menu" class="sidebar-menu" (mouseover)="revealMenu()" (mouseleave)="collapseMenu()">
      <ul class="accordion" id="mainSidemenu">
        <li *ngFor="let menu of menuItems" [ngClass]="{'accordion-item': menu.submenu}">
          <a *ngIf="!menu.submenu" [routerLink]="menu.link" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">
            <i [class]="'i i-' + menu.icon"></i>
            <span>{{ menu.label }}</span>
          </a>
          <button *ngIf="menu.submenu && menu.id !== 'payroll'" class="accordion-button" type="button"
            data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse_'+menu.id" aria-expanded="true"
            [attr.aria-controls]="'collapse_'+menu.id">
            <i [class]="'i i-' + menu.icon"></i>
            <span [id]="'menu_'+menu.id"> {{ menu.label }}</span>
          </button>
          <div *ngIf="menu.submenu && menu.id !== 'payroll'" id="collapse_{{menu.id}}"
            class="accordion-collapse collapse" [attr.aria-labelledby]="'menu_'+menu.id" data-bs-parent="#mainSidemenu">
            <ul class="accordion-body">
              <li *ngFor="let submenu of menu.submenu">
                <a [routerLink]="[submenu.link]" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                  {{submenu.label}}
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div> -->

<!-- <li class="menu-title">Main</li>
<li *ngFor="let menu of menuItems" [ngClass]="{
          'accordion-item': menu.submenu
        }">
  <a *ngIf="!menu.submenu" [routerLink]="menu.link" [routerLinkActive]="'active'">
    <i [class]="'i i-' + menu.icon"></i>
    <span>{{ menu.label }}</span>
  </a>
  <button *ngIf="menu.submenu" class="accordion-button" type="button" data-bs-toggle="collapse"
    [attr.data-bs-target]="'#collapse_'+menu.id" aria-expanded="true" [attr.aria-controls]="'collapse_'+menu.id">
    <i [class]="'i i-' + menu.icon"></i>
    <span [id]="'menu_'+menu.id"> {{ menu.label }}</span>
  </button>
  <div *ngIf="menu.submenu" id="collapse_{{menu.id}}" class="accordion-collapse collapse"
    [attr.aria-labelledby]="'menu_'+menu.id" data-bs-parent="#mainSidemenu">
    <ul class="accordion-body">
      <li *ngFor="let submenu of menu.submenu">
        <a [routerLink]="[submenu.link]">{{submenu.label}}</a>
      </li>
    </ul>
  </div>
</li> -->
<!-- <li class="menu-title">Main</li> -->