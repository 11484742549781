import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegment, UrlSegmentGroup } from "@angular/router";
import { FormDefault, Model } from "./model.view";
import { SysService } from "../services/sys.service";
import { ApiService } from "../services/api.service";
import { ViewAction } from "./view.action";
import { Injectable } from "@angular/core";
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { AppConstants } from "../../_config";


@Injectable()
export class View {

  model!: Model;
  private dialog: boolean = false;
  private replaceRoutes = false;
  private segments: UrlSegment[] | undefined;
  private firstPath: UrlSegment | undefined;
  // private route: ActivatedRoute;

  constructor(private router: Router, private location: Location, public sysService: SysService, private apiService: ApiService, private confirmationService: ConfirmationService) {
    this.setSegment();
  }
  setPopup(popup: boolean) {
    this.dialog = popup;
  }
  get popup() {
    return this.dialog;
  }

  private setSegment() {
    const tree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (g) {
      this.segments = g.segments;
      this.firstPath = this.segments[0];
    }
  }

  getPath(index: number) {
    if (this.segments && this.segments.length < index) {
      return this.segments[index].toString();
    }
    return '';
  }
  getParam(index: number, name: string) {
    if (this.segments && this.segments.length < index) {
      const c = this.segments[index].parameters[name];
      return c ? c : '';
    }
    return '';
  }

  get form() {
    return this.model.form;
  }

  switchActionSearch(servicePath: string, route: ActivatedRoute, action: ViewAction, replaceRoutes: any = true) {
    this.toViewAction(servicePath, route, action, replaceRoutes, false);
  }
  switchAction(servicePath: string, route: ActivatedRoute, action: ViewAction, replaceRoutes: any = true) {
    this.toViewAction(servicePath, route, action, replaceRoutes, true);
  }

  private async toViewAction(servicePath: string, route: ActivatedRoute, action: ViewAction, replaceRoutes: any = true, loadlist: boolean = true) {
    this.nextState('none');
    this.sysService.clear();
    this.setSegment();
    route.url.subscribe(async (params) => {
      if (params && params[0]) {
        const r = params[0].path;
        if (r) {
          if (r === 'new' || r.startsWith('new-')) {
            this.replaceRoutes = false;
            params[1] ? action.add(`${params[1].path}`) : action.add(null);
          } else if (r === 'edit' || r.startsWith('edit-')) {
            this.replaceRoutes = false; action.edit(`${params[1].path}`);
          } else if (r === 'view' || r.startsWith('view-')) {
            this.replaceRoutes = false; action.view(`${params[1].path}`);
          } else if (r === 'list' || r.startsWith('list-')) {
            this.replaceRoutes = false;
            params[1] ? action.list(`${params[1].path}`) : action.list(null);
            replaceRoutes = false;
          }
        }
      } else if (loadlist || servicePath === 'FAKE') {
        this.replaceRoutes = false;
        params[1] ? action.list(`${params[1].path}`) : action.list(null);
        this.replaceRoutes = replaceRoutes; //IT was false before changed to make it work
        // } else if (loadlist) {
        //   this.replaceRoutes = false;
        //   if (this.model && this.model.state === 'none') {
        //     params[1] ? action.list(`${params[1].path}`) : action.list(null);
        //     replaceRoutes = false;
        //   }
      } else {
        if (this.model && this.model.state === 'none') {
          this.nextState('list');
        }
      }
      this.replaceRoutes = replaceRoutes;
    });
    return this;
  }

  switchView(servicePath: string, route: ActivatedRoute, replaceRoutes: any = true) {
    this.toViewRoute(servicePath, route, replaceRoutes, true);
  }
  switchViewSearch(servicePath: string, route: ActivatedRoute, replaceRoutes: any = true) {
    this.toViewRoute(servicePath, route, replaceRoutes, false);
  }

  /** Cahange view based on route **/
  private async toViewRoute(servicePath: string, route: ActivatedRoute, replaceRoutes: any = true, loadlist: boolean = true) {
    this.nextState('none');
    this.sysService.clear();
    this.setSegment();
    route.url.subscribe(async params => {
      if (params && params[0]) {
        const r = params[0].path;
        if (r) {
          if (r === 'new') {
            this.replaceRoutes = false; this.toNew();
          } else if (r === 'edit') {
            this.replaceRoutes = false; await this.toEditFetch(`${servicePath}/${params[1].path}`, `${params[1].path}`);
          } else if (r === 'view') {
            this.replaceRoutes = false; await this.toViewFetch(`${servicePath}/${params[1].path}`, `${params[1].path}`);
          }
        }
      } else if (loadlist || servicePath === 'FAKE') {
        this.replaceRoutes = false; await this.toListView(servicePath);
      }
      this.replaceRoutes = replaceRoutes;
    });
    if (this.model && this.model.state === 'none')
      this.nextState('list');
    return this;
  }

  param(key: any) {
    return this.model.param(key);
  }

  setParam(key: any, value: any) {
    return this.model.setParam(key, value);
  }

  of(model: Model, replaceRoutes: any = true) {
    this.replaceRoutes = replaceRoutes;
    this.model = model;
    return this;
  }

  setValue(name: string, value: any) {
    this.model.set(name, value);
  }

  value(name: string, defaultValue = null) {
    return this.model.get(name, defaultValue);
  }

  toNewData(servicePath: string = '', reset: boolean = true, mFailed: string = 'DEFAULT') {
    this.toNew(reset);
    return this.getFormData(servicePath, mFailed);
  }

  toNew(reset: boolean = true, keep: boolean = false) {
    this.sysService.clear(keep);
    if (reset) {
      this.model.reset();
      this.model.selection = [];
      this.model.searchTxt = '';
    }
    this.setState('new', null);
    const fd: FormDefault = this.model.formDefault;
    if (fd && fd.fields && fd.values) {
      let i = 0;
      for (const k of fd.fields) {
        this.model.set(k, fd.values[i]);
        i++;
      }
    }
    this.model.view = this.model.value();
  }


  toListFetch(servicePath: string = 'FAKE', mFailed: string = 'NONE') {
    return this._toListView(servicePath, mFailed, true);
  }

  private page: number = 0;
  private filter: string = '';
  toListLazyView(servicePath: string = 'FAKE', search: boolean = this.model.isEmptyList(), mFailed: string = 'NONE') {
    this.sysService.clear();
    if (search) {
      this.toListView();
      this.toListLazyFetch(servicePath, search);
    }
    else {
      this.toListView();
    }
  }



  psize: number = 0;
  toListLazyFetch(servicePath: string = 'FAKE', search: boolean = false, event?: LazyLoadEvent, mFailed: string = 'NONE') {

    if (!this.model.islist)
      return;
    // console.log(event)
    let size = this.sysService.rows;
    let change: boolean = false;
    let p: number = 0;
    let f: string = '';
    let first = event?.first ? event.first : 0;
    if (search) {
      first = 0;
    }
    if (this.model?.p?.toString()) { //search true if there is params
      search = true;
    }
    size = event?.rows ? event.rows : size;
    if (first) {
      p = Math.round(first / size);
      this.sysService.clear();
    }

    if (event && event.globalFilter) {
      f = event.globalFilter;
      this.sysService.clear();
    }

    change = (this.page !== p || this.psize !== size);
    this.psize = size;
    if (event && event.globalFilter) {
      this.filter = event.globalFilter;
    } else if (this.filter) { //trigger the filter after filter and clear text
      change = true;
      this.filter = '';
      this.model.searchTxt = '';
    }
    // console.log(p, this.page)
    this.page = p;
    if (this.isEmptyList() || event?.globalFilter || event?.sortField || change || search) {
      return this._toListLazyFetch(servicePath, event, search, size, mFailed);
    } else {
      this.setState('list', null);
      return this.model.list;
    }
  }

  private _toListLazyFetch(servicePath: string = 'FAKE', event?: LazyLoadEvent, search: boolean = false, size: number = 0, mFailed: string = 'NONE') {

    let order = 'asc'
    if (servicePath.includes("?"))
      servicePath += "&"
    else
      servicePath += "?"

    if (event?.sortOrder)
      order = event?.sortOrder === 1 ? 'asc' : 'desc'
    if (event?.globalFilter)
      servicePath += `filter=${event.globalFilter}&`
    if (event?.sortField)
      servicePath += `sort=${event.sortField},${order}&`


    servicePath += `order=${order}&page=${this.page}&size=${size}`
    return this._toListView(servicePath, mFailed, true);
  }

  getDataList(servicePath: string = 'FAKE', mFailed: string = 'NONE') {
    return this._toListView(servicePath, mFailed, true, true, true);
  }

  toListView(servicePath: string = 'FAKE', mFailed: string = 'NONE') {
    return this._toListView(servicePath, mFailed, this.model.isEmptyList());
  }

  toList(list: []) {
    this.model.list = list;
    this.setState('list', null);
  }

  private _toListView(servicePath: string = 'FAKE', mFailed: string = 'NONE', empty: boolean, islist: boolean = true, isview: boolean = false) {
    this.sysService.popup = this.popup;
    if (servicePath !== 'FAKE' && empty) {
      this.sysService.setLoading(true);
      return this.list(servicePath, mFailed, islist, isview).finally(() => {
        if (islist && !isview)
          this.setState('list', null);
        else if (this.sysService.loading) { this.sysService.setLoading(false); }
      });
    } else {
      if (islist && !isview)
        this.setState('list', null);
      else if (this.sysService.loading) { this.sysService.setLoading(false); }
      return null;
    }
  }

  async list(servicePath: string = '', mFailed: string = 'NONE', islist: boolean = true, isview: boolean = true) {
    this.sysService.popup = this.popup;
    return this.getData(servicePath).then(data => {
      this.model.clearParams();
      if (islist) {
        if (data instanceof Array) { this.model.list = data; }
        else if (data?.list) { this.model.list = data.list; this.model.total = data?.total }
      }
      if (isview) this.model.view = data;
      if (this.model.isEmptyList() && mFailed !== 'NONE') { this.sysService.info(mFailed); }
      return data;
    });
  }



  toEditView(id: any) {
    this.setState('edit', id);
    this.model.selection = [];
  }

  toEdit(row: any, id: any) {
    this.sysService.clear();
    this.model.selection = [];
    if (row) {
      this.model.setToNextEdit(row, this.form, true);
      this.toEditView(id);
    } else {
      this.sysService.error(AppConstants.noRecordFound);
    }
  }

  toClone(row: any, pkName: string, form: any = this.form) {
    this.sysService.clear();
    this.model.setToForm(row, form).field(pkName).setValue(null);
    this.setState('new', null);
    this.model.selection = [];
  }

  toView() {
    this.nextState('view');
    this.model.selection = [];
  }
  toViewFetch(servicePath: string = '', id: any) {
    this.model.id = id;
    return this._fetch(servicePath, 'view', id);
  }

  async toEditFetch(servicePath: string = '', id: any, mFailed: string = 'DEFAULT') {
    this.model.id = id;
    return this._fetch(servicePath, 'edit', id, mFailed);
  }

  private _fetch(servicePath: string = '', state: string, id: any, mFailed: string = 'DEFAULT') {
    this.sysService.clear();
    this.model.selection = [];
    this.sysService.setLoading(true);

    if (servicePath.startsWith('FAKE')) {
      let fl = this.model.list.filter((val: { id: any; }) => val.id === id);
      this.model.setToNextEdit(fl[0], this.form, true);
      this.setState(state, id);
      return this.model.form.value;
    }
    return this.get(servicePath)
      .then(
        res => { this.setState(state, id); this.model.setToNextEdit(res?.data ? res.data : res, this.form, false); return res?.data ? res.data : res },
        error => {
          this.sysService.handleError(error, mFailed === 'DEFAULT' ? AppConstants.selectFailed : mFailed);
        }
      ).finally(() => {
        this.sysService.setLoading(false); this.model.clearParams();
      });
  }

  getFormData(servicePath: string = '', mFailed: string = 'DEFAULT') {
    return this._getData(servicePath, true, mFailed, true);
  }

  getData(servicePath: string = '', mFailed: string = 'DEFAULT') {
    return this._getData(servicePath, false, mFailed, false);
  }

  /**
    Get the data from an api call.
  */
  _getData(servicePath: string = '', updateView: boolean, mFailed: string = 'DEFAULT', setToForm: boolean = false) {
    return this.apiService.getm(servicePath, this.model)
      .then(res => {
        if (setToForm) {
          if (updateView) {
            this.model.updateFormView(res?.data ? res.data : res);
          } else {
            this.model.setToForm(res?.data ? res.data : res);
          }
        }
        return res?.data ? res.data : res
      }).finally(() => { this.model.clearParams(); })
  }


  get(servicePath: string = '') {
    return this.apiService.getm(servicePath, this.model);
  }


  /**
    Delete a record.
    
    const id = model.get('id');      // get the primary key field from form   
    toDelete(`user/delete/${id}`, id)  // user/delete/12  user with id 12
    
    const id = model.param('id');    // id in the model is set to the param id=12
    toDelete('user/delete', id);     // user/delete     id=value will be passed as parameter
  */
  toDelete(servicePath: string = '', pkName: string, id: any,
    message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this._toDelete(servicePath, pkName, id, this.model.state, message);
  }

  toDeleteList(servicePath: string = '', pkName: string, id: any, message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this._toDelete(servicePath, pkName, id, 'list', message);
  }
  // toDeleteList1(
  //   servicePath: string = '',
  //   pkName: string,
  //   id: any,
  //   message: any = {
  //     onSuccess: null,
  //     onError: null,
  //     confirm: AppConstants.deleteConfirm,
  //     success: "DEFAULT",
  //     failed: "DEFAULT"
  //   },
  //   callback: () => void // Add a callback function parameter
  // ) {
  //   // Call _toDelete function with the specified parameters
  //   this._toDelete(servicePath, pkName, id, 'list', message);

  //   // Call the callback function after the delete operation is successful
  //   if (message.onSuccess) {
  //     message.onSuccess();
  //   }

  //   // Add any other logic you need here
  // }

  toDeleteNew(servicePath: string = '', pkName: string, id: any, message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this._toDelete(servicePath, pkName, id, 'new', message);
  }

  private messageDefaults(message: any) {
    if (message) {
      if (!message.success) message.success = 'DEFAULT'
      if (!message.failed) message.failed = 'DEFAULT'
      if (!message.confirm) message.confirm = AppConstants.deleteConfirm;
    }
  }

  private async _toDelete(servicePath: string = '', pkName: string, id: any, state: string = '', message: any = { confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this.preProcess();
    this.messageDefaults(message)
    this.confirmationService.confirm({
      message: message.confirm,
      header: AppConstants.confirm,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sysService.setLoading(true);
        if (servicePath === 'FAKE') {
          this.afterDelete(state, pkName, id, message);
          return null;
        }
        else {
          return this.delete(servicePath).then(
            res => { this.afterDelete(state, pkName, id, message); },
            err => { this.deleteFail(err, message) }
          ).finally(() => {
            this.sysService.setLoading(false);
          });
        }
      }
      // ,reject: () => { c = false}
    });
  }
  private deleteFail(err: any, message: any) {            //FIXME what happens on handleError
    //old this.sysService.error(message.failed === 'DEFAULT' ? AppConstants.deleteFailed : message.failed);
    this.sysService.handleError(err, message.failed === 'DEFAULT' ? AppConstants.deleteFailed : message.failed);
    if (message.onError) { message.onError() }
  }
  private preProcess() {
    this.sysService.clear();
    this.sysService.submitted = true;
    this.sysService.popup = this.popup;
  }

  private afterDelete(state: string = '', pkName: any, id: any, message: any) {
    if (id) { } else { id = this.model.get(pkName); }
    if (id) {
      const list = this.model.list.filter((val: { [x: string]: any; }) => val[pkName] !== id); // removing deleted record from list
      this.model.list = list;
      this.model.total--;                                           // reducing the total count
    }
    this.sysService.success(message.success === 'DEFAULT' ? AppConstants.deleteSuccess : message.success);
    this.setState(state, null);
    if (message.onSuccess) {
      message.onSuccess();
    }
  }

  delete(servicePath: string = '', body: any = null) {
    return this.apiService.delete(servicePath, this.model, body);
  }

  toDeleteSelectionModels(servicePath: string = '', message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    return this._toDeleteSelection(servicePath, 'MODEL', this.model.state, message);
  }

  toDeleteSelection(servicePath: string = '', message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    return this._toDeleteSelection(servicePath, "id", this.model.state, message);
  }

  private async _toDeleteSelection(servicePath: string = '', pkName: string = 'id', state: string = '', message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this.preProcess();
    this.messageDefaults(message)
    this.confirmationService.confirm({
      message: message.confirm,
      header: AppConstants.confirm,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.sysService.setLoading(true);
        if (servicePath === 'FAKE') {
          return this.afterDeleteSelection(state, message);
        }
        else {
          let dl: any = [];
          if (pkName === 'MODEL') {
            dl = this.model.selection;
          }
          else {
            if (!this.isEmptyList(this.model.selection)) {
              for (const k of this.model.selection) {
                dl.push(k.id);
              }
            }
          }
          return this.delete(servicePath, dl).then(
            res => { this.afterDeleteSelection(state, message); },
            err => { this.deleteFail(err, message) }
          ).finally(() => {
            this.sysService.setLoading(false);
          });
        }
      }
      // , reject: () => { return false; }
    });
  }

  private afterDeleteSelection(state: string = '', message: any) {
    this.model.list = this.model.list.filter((val: any) => !this.model.selection.includes(val)); // removing deleted record from list
    this.model.total -= this.model.selection.length;
    this.model.selection = [];                                         // reducing the total count
    this.sysService.success(message.success === 'DEFAULT' ? AppConstants.deleteSuccess : message.success);
    this.setState(state, null);
    if (message.onSuccess) {
      message.onSuccess();
    }
  }

  /**
    Save a record.
    const id = model.get('id');      // get the primary key field from form   
    toSave(`user`, 'id')  // user  user get 
    const id = model.param('id');    // id in the model is set to the param id=12
    view.toDelete(`product/${product.id}`, product);
  */
  toSaveFetch(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, this.model.state === 'new' ? 'add' : this.model.state, 'NONE', mSuccess, mFailed, servicePath);
  }
  toSaveListFetch(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, 'list', 'NONE', mSuccess, mFailed, servicePath);
  }
  // toSave(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
  //   return this._toSave(servicePath, pkName, this.model.state === 'new' ? 'add' : this.model.state, 'NONE', mSuccess, mFailed,'',data);
  // }
  toSave(servicePath: string = '', pkName: string, data: any = null, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, this.model.state === 'new' ? 'add' : this.model.state, 'NONE', mSuccess, mFailed, '', data);
  }

  toSaveList(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, 'list', 'NONE', mSuccess, mFailed);
  }
  toSaveNewFetch(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, 'new', 'NONE', mSuccess, mFailed, servicePath).then(d => { if (d) this.toNew(true, true) });
  }
  toSaveNew(servicePath: string = '', pkName: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, 'new', 'NONE', mSuccess, mFailed).then(d => { this.toNew() });
  }
  toSaveNav(servicePath: string = '', pkName: string, navPath: string, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    this._toSave(servicePath, pkName, 'new', navPath, mSuccess, mFailed);
  }
  toSaveData(servicePath: string = '', pkName: string, data: any, mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT') {
    return this._toSave(servicePath, pkName, this.model.state === 'new' ? 'add' : this.model.state, 'NONE', mSuccess, mFailed, '', data);
  }

  isValid(message: boolean = true) {
    this.preProcess();
    if (this.model.form.invalid) {
      if (message) {
        this.sysService.error(AppConstants.validationFailed);
      }
      return false;
    }
    return true;
  }

  private async _toSave(servicePath: string = '', pkName: string, state: string = '', navPath: string = 'NONE', mSuccess: string = 'DEFAULT', mFailed: string = 'DEFAULT', listItemFetchPath: string = '', data: any | null = null) {
    if (this.isValid()) {
      this.sysService.setLoading(true);
      let pk = this.model.get(pkName, null);
      if (servicePath === 'FAKE') {
        if (!pk) {
          this.model.set(pkName, new Date().getTime());
        }
        this.afterSave(this.model.form.value, pkName, state, navPath, mSuccess, mFailed, listItemFetchPath, pk);
        return this.model.form.value;
      } else {
        return this.apiService.savem(servicePath, this.model, pk, data).then(
          res => { return this.afterSave(res, pkName, state, navPath, mSuccess, mFailed, listItemFetchPath, pk); },
          // error => {        
          //   this.sysService.handleError(error);
          // }
        ).finally(() => {
          this.sysService.setLoading(false);
        });
      }
    }
  }

  private async afterSave(data: any, pkName: string, state: string, navPath: string, mSuccess: string, mFailed: string, listItemFetchPath: string, isedit: any) {
    this.sysService.success(mSuccess === 'DEFAULT' ? AppConstants.saveSuccess : mSuccess);
    const pk = this.model.get(pkName, null);
    this.model.setToForm(data);
    this.model.id = this.model.get(pkName, null);
    if (listItemFetchPath === '') {// Set the data from server side back to form and add to list or update list
      //await this.model.setFormToList(pkName, pk, this.model.form.value, isedit);
      let merged = Object.assign(this.model.form.value, data); // merge form data and data from response since both are same obj refrenece
      await this.model.setFormToList(pkName, pk, merged); //shouldnt this be just data than form commented above
    } else {//if(this.page === 0) { //for lazy if we are on second page we dont need to do this
      // Set the data from server side back to form and add to list or update list
      await this.afterSaveUpdateList(listItemFetchPath, pkName);
    }
    const id = this.model.get(pkName, null);
    if (id && state === 'add') { state = 'edit'; }
    if (state === 'add') { state = 'new'; }
    this.setState(state, id);
    if (navPath !== 'NONE') {         // to navigate to list or stay on edit
      this.sysService.navigate([navPath], true);
    }
    return data;
  }

  private async afterSaveUpdateList(listItemFetchPath: string = '', pkName: string,) {
    this.setParam(pkName, this.model.get(pkName, null));
    return this.apiService.getm(`${listItemFetchPath}`, this.model).finally(
      () => { this.model.clearParams() }
    ).then(
      res => {
        const d = res?.data ? res.data : res?.list ? res.list : res
        if (d && d.length > 0) {
          //you can loop and push but now just the first element
          this.model.setFormToList(pkName, this.model.get(pkName, null), d[0]);
          this.model.total = this.model.total + 1; //Adding the total for lazy pagination
          this.model.clearParams();
        }
        // }, error => {        
        //   this.sysService.handleError(error);
      })
  }

  nextState(state: string) {
    if (this.model) {
      this.model.state = state;       // to change the status after delete to list or new
      this.model.islist = ("list" === state);
      this.model.isform = (this.isNew() || this.isEdit());
      this.model.isformview = (this.model.isform || this.isView());
    }
  }

  private setState(state: string, id: any) {
    this.nextState(state);
    if (this.sysService.loading) { this.sysService.setLoading(false); }
    this.replaceRoute(id);
  }

  private replaceRoute(path: string = '') {
    if (path && path.length !== 0) {
      path = '' + path;
      if (!path.startsWith('/')) {
        path = "/" + path;
      }
    } else {
      path = '';
    }
    // if (this.replaceRoutes) {
    //   if (this.model.islist) {
    //     this.location.replaceState(`${this.firstPath}`); //Commented for breadcrumb to work
    //     //  this.router.navigate([`${this.firstPath}`]);
    //     //  this.breadCrumb(this.route);

    //   } else {
    //     this.location.replaceState(`${this.firstPath}/${this.model.state}${path}`);//Commented for breadcrumb to work
    //     // this.router.navigate([`${this.firstPath}/${this.model.state}${path}`]); 
    //     // this.breadCrumb(this.route);
    //   }
    // }
  }

  isNew() {
    return this.model.isNew();
  }

  isEdit() {
    return this.model.isEdit();
  }

  isView() {
    return this.model.isView();
  }

  setParamFromModel(model: Model = this.model) {
    this.model.setParamFromModel(model);
  }

  isEmptyList(list = this.model.list) {
    return this.model.isEmptyList(list);
  }

  post(servicePath: string = '', contentType = 'application/json') {
    return this.apiService.postm(servicePath, this.model, this.model.form.value, contentType);
  }

  put(servicePath: string = '', contentType = 'application/json') {
    return this.apiService.put(servicePath, this.model, this.model.form.value, contentType);
  }
  // download(servicePath: string = '', filename: string = '', open: boolean = false) {
  //   this.apiService.downloadm(servicePath, this.model)
  //     .subscribe(
  //       data => {
  //         this.sysService.setLoading(false);
  //         const mimeTypes = { 'pdf': 'application/pdf', 'jpg': 'image/jpeg', 'jpeg': 'image/jpeg', 'png': 'image/png' };
  //         if (open) {
  //           let extension: any = filename?.split('.').pop();
  //           open = (extension === 'pdf' || extension === 'jpg' || extension === 'jpeg' || extension === 'png');
  //           if (open) window.open(URL.createObjectURL(new Blob([data], { type: mimeTypes[extension] })), '_blank');
  //         }
  //         if (!open) {
  //           let downloadLink = document.createElement('a');
  //           downloadLink.href = window.URL.createObjectURL(new Blob([data]));
  //           if (filename !== '') {
  //             downloadLink.setAttribute('download', filename);
  //             document.body.appendChild(downloadLink);
  //             downloadLink.click();
  //           }
  //         }
  //       },
  //       error => {
  //         if(error.status===404){
  //           this.sysService.error(`${filename} is not found.`)

  //         }
  //     else{
  //       this.sysService.error(`Unable to download ${filename}. Please try again..`)
  //     }
  //       }
  //     );
  // }


  // downloadOpen(servicePath: string = '', fileType: string = 'any') {
  //   this.download(servicePath, fileType, true);
  // }

  breadItems: MenuItem[] = [];
  breadFirst: any;
  // breadCrumb(index: number, label: string, path: string = this.firstPath.toString()) {
  //   this.breadItems = [];
  //   if (index > 0) {
  //     let val = '';
  //     this.breadItems.push(this.breadFirst);
  //     this.breadItems.push({ label: label });
  //   } else {
  //     this.breadFirst = { label: label, command: (event) => { this.sysService.link(path, this.router) } };
  //     this.breadItems.push({ label: label });
  //   }
  // }
  image(servicePath: string = '') {
    return this.apiService.downloadm(servicePath, this.model);
  }

  toRemove(servicePath: string = '', pkName: string, id: any,
    message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "" }) {
    return this._toRemove(servicePath, pkName, id, this.model.state, message);
  }

  private async _toRemove(servicePath: string = '', pkName: string, id: any, state: string = '', message: any = { onSuccess: null, onError: null, confirm: AppConstants.deleteConfirm, success: "DEFAULT", failed: "DEFAULT" }) {
    this.preProcess();
    this.sysService.setLoading(true);
    if (servicePath === 'FAKE') {
      return this.afterDelete(state, pkName, id, message);
    }
    else {
      return this.delete(servicePath).then(
        res => { this.afterDelete(state, pkName, id, message); return res; },
        err => { this.deleteFail(err, message); return err; }
      ).finally(() => {
        this.sysService.setLoading(false);
      });
    }
  }

}
