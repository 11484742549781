import { Component } from '@angular/core';
import { UserService } from 'src/app/_common/services/user.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {


  filteredMenuItems: any[] = [];

  constructor(private userService: UserService) { }

  ngOnInit() {
    // Load permissions from localStorage
    this.userService.getPermissions();
    this.filterMenuItems();
  }

  menuItems = [
    {
      id: "dashboard",
      label: 'Dashboard',
      icon: 'dashboard',
      link: '/home',
      requiredPermission: 'addDashboard'
    },
    {
      id: "coordinators",
      label: 'Coordinators',
      icon: 'doctors',
      link: '/users',
      requiredPermission: 'addCoordinator'
    },
    {
      id: "patients",
      label: 'Patients',
      icon: 'person',
      link: '/patients',
      requiredPermission: 'addPatient'
    },
    {
      id: "scale",
      label: 'Scale',
      icon: 'scale',
      link: '/scale',
      requiredPermission: 'addScale'
    },
    {
      id: "hospitals",
      label: 'Hospitals',
      icon: 'hospital-o',
      link: '/hospitals',
      requiredPermission: 'addHospital'
    },
    {
      id: "medicines",
      label: 'Patient Medicines',
      icon: 'i i-pills',
      link: '/medicine',
      requiredPermission: 'addMedicine'
    },
    {
      id: " Test",
      label: 'Tests',
      icon: 'vial',
      link: '/tests',
      requiredPermission: 'viewTests'
    },
    {
      id: "infographics",
      label: 'Infographics',
      icon: 'subscriptions',
      link: '/infographics',
      requiredPermission: 'addInfographics'
    },
    {
      id: "Reports",
      label: 'Reports',
      icon: 'file-prescription',
      link: '/reports',
      requiredPermission: 'addReport'
    },
    {
      id: "settings",
      label: 'Settings',
      icon: 'settings',
      requiredPermission: 'addConfiguration',
      submenu: [
        {
          id: 'Medicine',
          label: 'Medicine',
          icon: 'i i-pills',
          link: '/medicines',
          requiredPermission: 'addMedicine'
        },
      ],
      // requiredPermission: null
    }
  ];

  filterMenuItems() {
    this.filteredMenuItems = this.menuItems.filter(item =>
      this.userService.hasPermission(item.requiredPermission) && (!item.submenu || this.filterSubmenu(item.submenu).length > 0)
    );
  }

  // Filter submenus based on user permissions
  filterSubmenu(submenu: any[]) {
    return submenu.filter(subItem => this.userService.hasPermission(subItem.requiredPermission));
  }
  revealMenu() {
    if (document.querySelector('body')?.classList.contains('mini-sidebar')) {
      document.querySelector('body')?.classList.add('expand-menu');
    } else {
      document.querySelector('body')?.classList.remove('expand-menu');
    }
  }

  collapseMenu() {
    document.querySelector('body')?.classList.remove('expand-menu');
  }

  activeMenuId: string | null = null;

  toggleSubmenu(menuId: string) {
    this.activeMenuId = this.activeMenuId === menuId ? null : menuId;
  }

  isSubmenuOpen(menuId: string): boolean {
    return this.activeMenuId === menuId;
  }

  // toggleSubmenu(id: string) {
  //   const menuElement = document.querySelector(`li[data-id="${id}"]`);
  //   if (menuElement) {
  //     menuElement.classList.toggle('active');
  //   }
  // }

}
