import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorInterceptorProviders } from './_common/helpers/error.interceptor';
import { AuthInterceptorProviders } from './_common/helpers/auth.interceptor';
import { SysService } from './_common/services/sys.service';
import { ViewFactory } from './_common/components/view.factory.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog/dynamicdialog-config';
import { DynamicDialogRef } from 'primeng/dynamicdialog/dynamicdialog-ref';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from './_common/shared.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { SessionTimeoutComponent } from './session-timeout/session-timeout.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    SessionTimeoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ConfirmDialogModule,
    ButtonModule,
    SharedModule,
    BrowserAnimationsModule,
    CommonModule
  ],
  providers: [
    AuthInterceptorProviders,
    ErrorInterceptorProviders,
    MessageService,
    ConfirmationService,
    SysService,
    DialogService,
    ViewFactory,


  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
