import { Component } from '@angular/core';
import { UserService } from 'src/app/_common/services/user.service';
import { SysService, View, ViewFactory } from 'src/app/_common';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  activeMenuId: string | null = null;
  filteredMenuItems: any[] = [];

  constructor(private userService: UserService,
    public router: Router,
    public sysService: SysService,
  ) { }

  ngOnInit() {
    // Load permissions from localStorage
    this.userService.getPermissions();
    this.filterMenuItems();
  }

  menuItems = [
    {
      id: "dashboard",
      label: 'Dashboard',
      icon: 'dashboard',
      link: '/home',
      requiredPermission: 'addDashboard'
    },
    {
      id: "coordinators",
      label: 'Coordinators',
      icon: 'doctors',
      link: '/users',
      requiredPermission: 'addCoordinator'
    },
    {
      id: "patients",
      label: 'Patients',
      icon: 'person',
      link: '/patients',
      requiredPermission: 'addPatient'
    },
    {
      id: "scale",
      label: 'Scale',
      icon: 'scale',
      link: '/scale',
      requiredPermission: 'addScale'
    },
    {
      id: "hospitals",
      label: 'Hospitals',
      icon: 'hospital-o',
      link: '/hospitals',
      requiredPermission: 'addHospital'
    },
    // {
    //   id: "hospitalss",
    //   label: 'Hospitalsss',
    //   icon: 'hospital-o',
    //   link: '/hospital',
    //   requiredPermission: 'addHospital'
    // },
    {
      id: "medicines",
      label: 'Medicine History',
      icon: 'i i-pills',
      link: '/medicine',
      requiredPermission: 'addMedicine'
    },
    {
      id: " Test",
      label: 'Test History',
      icon: 'vial',
      link: '/tests',
      requiredPermission: 'viewTests'
    },
    {
      id: "infographics",
      label: 'Infographics',
      icon: 'subscriptions',
      link: '/infographics',
      requiredPermission: 'addInfographics'
    },
    // {
    //   id: "Reports",
    //   label: 'Reports',
    //   icon: 'file-prescription',
    //   link: '/reports',
    //   requiredPermission: 'addReport'
    // },
    {
      id: "settings",
      label: 'Settings',
      icon: 'settings',
      requiredPermission: 'addConfiguration',
      submenu: [
        {
          id: 'Medicine',
          label: 'Medicine',
          icon: 'i i-pills',
          link: '/medicines',
          requiredPermission: 'addMedicine'
        },
      ],
      // requiredPermission: null
    }
  ];

  // New model of meuItems
  // menuItems = [
  //   {
  //     id: "dashboard",
  //     label: 'Dashboard',
  //     icon: 'dashboard',
  //     requiredPermission: 'addDashboard',
  //     command: (event: any) => {
  //       this.link('/home');
  //     }
  //   },
  //   {
  //     id: "coordinators",
  //     label: 'Coordinators',
  //     icon: 'doctors',
  //     requiredPermission: 'addCoordinator',
  //     command: (event: any) => {
  //       this.link('/users');
  //     }
  //   },
  //   {
  //     id: "patients",
  //     label: 'Patients',
  //     icon: 'person',
  //     requiredPermission: 'addPatient',
  //     command: (event: any) => {
  //       this.link('/patients');
  //     }
  //   },
  //   {
  //     id: "scale",
  //     label: 'Scale',
  //     icon: 'scale',
  //     requiredPermission: 'addScale',
  //     command: (event: any) => {
  //       this.link('/scale');
  //     }
  //   },
  //   {
  //     id: "hospitals",
  //     label: 'Hospitals',
  //     icon: 'hospital-o',
  //     requiredPermission: 'addHospital',
  //     command: (event: any) => {
  //       this.link('/hospitals');
  //     }
  //   },
  //   {
  //     id: "medicines",
  //     label: 'Medicine History',
  //     icon: 'i i-pills',
  //     requiredPermission: 'addMedicine',
  //     command: (event: any) => {
  //       this.link('/medicine');
  //     }
  //   },
  //   {
  //     id: "test",
  //     label: 'Test History',
  //     icon: 'vial',
  //     requiredPermission: 'viewTests',
  //     command: (event: any) => {
  //       this.link('/tests');
  //     }
  //   },
  //   {
  //     id: "infographics",
  //     label: 'Infographics',
  //     icon: 'subscriptions',
  //     requiredPermission: 'addInfographics',
  //     command: (event: any) => {
  //       this.link('/infographics');
  //     }
  //   },
  //   {
  //     id: "reports",
  //     label: 'Reports',
  //     icon: 'file-prescription',
  //     requiredPermission: 'addReport',
  //     command: (event: any) => {
  //       this.link('/reports');
  //     }
  //   },
  //   {
  //     id: "settings",
  //     label: 'Settings',
  //     icon: 'settings',
  //     requiredPermission: 'addConfiguration',
  //     submenu: [
  //       {
  //         id: 'medicine',
  //         label: 'Medicine',
  //         icon: 'i i-pills',
  //         requiredPermission: 'addMedicine',
  //         command: (event: any) => {
  //           this.link('/medicines');
  //         }
  //       }
  //     ]
  //   }
  // ];

  //link method
  link(path: string) {
    SysService.scrollTo(0);
    this.sysService.clear();
    this.router
      .navigateByUrl("/", { skipLocationChange: false })
      .then(() => this.router.navigate([path]));
  }

  // Filter menus based on user permissions
  filterMenuItems() {
    this.filteredMenuItems = this.menuItems.filter(item =>
      this.userService.hasPermission(item.requiredPermission) && (!item.submenu || this.filterSubmenu(item.submenu).length > 0)
    );
  }

  // Filter submenus based on user permissions
  filterSubmenu(submenu: any[]) {
    return submenu.filter(subItem => this.userService.hasPermission(subItem.requiredPermission));
  }


  revealMenu() {
    if (document.querySelector('body')?.classList.contains('mini-sidebar')) {
      document.querySelector('body')?.classList.add('expand-menu');
    } else {
      document.querySelector('body')?.classList.remove('expand-menu');
    }
  }

  collapseMenu() {
    document.querySelector('body')?.classList.remove('expand-menu');
  }



  toggleSubmenu(menuId: string) {
    this.activeMenuId = this.activeMenuId === menuId ? null : menuId;
  }

  isSubmenuOpen(menuId: string): boolean {
    return this.activeMenuId === menuId;
  }

  // toggleSubmenu(id: string) {
  //   const menuElement = document.querySelector(`li[data-id="${id}"]`);
  //   if (menuElement) {
  //     menuElement.classList.toggle('active');
  //   }
  // }

}
