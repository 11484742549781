export const environment = {
  production: false,
  appApi: 'https://mamors-api.infospica.in',
  backend: { spring: false, php: true, node: false }, //first true will be applied make spring false and then make node true to use node app
  auth: {
    login: 'https://mamors-api.infospica.in/api/auth/login',  //Node js '/signin/' // Spring Boot build in '/oauth/token/'
    logout: 'https://localhost:8084/baseservice/logout',
    register: 'https://localhost:8084/baseservice/signup',
    clientId: 'clientIdPassword',
    clientSecret: 'secret'  //Required for Spring boot, left empty for node js
  }
};

