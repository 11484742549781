import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'home', component: HomeComponent },
  { path: 'hospitals', loadChildren: () => import('./hospitals/hospitals.module').then(m => m.HospitalsModule) },
  { path: 'patients', loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule) },
  { path: 'users', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'doctors', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'scale', loadChildren: () => import('./scale/scale.module').then(m => m.ScaleModule) },
  { path: 'infographics', loadChildren: () => import('./infographics/infographics.module').then(m => m.InfographicsModule) },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'tests', loadChildren: () => import('./tests/tests.module').then(m => m.TestsModule) },
  { path: 'medicine', loadChildren: () => import('./medicine/medicine.module').then(m => m.MedicineModule) },
  { path: 'medicine-details', loadChildren: () => import('./medicineDetails/medicineDetails.module').then(m => m.MedicineDetailsModule) },
  { path: 'medicines', loadChildren: () => import('./master-medicine/master-medicine.module').then(m => m.MasterMedicineModule) },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
