import { Injectable } from '@angular/core';

import { StaticData } from './static.data';
@Injectable({
  providedIn: 'root'
})
export class StaticService {
  productStatus: any = [];
  activeStatus: any = [];
  piedmontConnectAutocomplete: any = [];
  merchantId: any = [];
  inactiveReason: any = [];
  biller: any = [];
  State: any = [];
  time: any = [];
  studyFilter: any = [];
  rejectReason: any = [];
  country: any = [];
  groupset: any = [];
  grouphrs: any = [];
  statusStudyListRadi: any = [];
  modality: any = [];
  groupmembers: any = [];
  Permission: any = [];
  NoPermission: any = [];
  ConservationBiller: any = [];
  Complaint: any = [];
  agentFeeSplitBusinessAccount: any = [];
  agentFeeSplitAccountType: any = [];
  agentFeeSplitFederalTaxClassification: any = [];
  expenseMemo: any = [];
  frozen: any = [];
  agentViewable: any = [];
  contactBySms: any = [];
  fundingMethod: any = [];
  contactByEmail: any = [];
  status: any = [];
  teleradiologyCoordinator: any = []
  reportType: any = [];
  statusStudyList: any = [];
  payStatus: any = [];
  visibilityRule: any = [];
  priorityStatus: any = [];
  dateType: any = [];
  statusRadi: any = [];
  TransactionType: any = [];
  boroughStatus: any = [];
  month: any = [];
  year: any = [];
  bonusType: any = [];
  gender: any = [];
  assignmentRule: any[]
  progresStatus: any[];
  ticketStatus: any[];
  approvalRule: any[];
  roleType: any[];
  hideCentreName: any[];
  templateType: any[];
  sharingScope: any[];
  subjectTicket: any[];
  userRole: any[];
  centerUser: any[];
  eClarityFee: any[];
  contactRoles: any[];
  contactRoles1: any[];
  contactRoles2: any[];
  hisStatus: any[];
  contactStatus1: any[];
  contactStatus: any[];
  paymentStatus: any[];
  makeItEditable: any[];
  rejectedBy: any[];
  constructor() {
    this.priorityStatus = StaticData.priorityStatus;
    this.progresStatus = StaticData.progresStatus;
    this.gender = StaticData.gender;
    this.hisStatus = StaticData.hisStatus;
    this.rejectedBy = StaticData.rejectedBy;
    this.subjectTicket = StaticData.subjectTicket;
    this.contactRoles = StaticData.contactRoles;
    this.contactRoles1 = StaticData.contactRoles1;
    this.contactRoles2 = StaticData.contactRoles2;

    this.contactStatus1 = StaticData.contactStatus1;
    this.studyFilter = StaticData.studyFilter;

    this.contactStatus = StaticData.contactStatus;
    this.activeStatus = StaticData.activeStatus;
    this.dateType = StaticData.dateType;
    this.bonusType = StaticData.bonusType;
    this.reportType = StaticData.reportType;
    this.rejectReason = StaticData.rejectReason;
    this.time = StaticData.time;
    this.paymentStatus = StaticData.paymentStatus;
    this.teleradiologyCoordinator = StaticData.teleradiologyCoordinator;
    this.groupset = StaticData.groupset;
    this.approvalRule = StaticData.approvalRule;
    this.grouphrs = StaticData.grouphrs;
    this.status = StaticData.status;
    this.ticketStatus = StaticData.ticketStatus;
    this.TransactionType = StaticData.TransactionType;
    this.payStatus = StaticData.payStatus;
    this.statusStudyList = StaticData.statusStudyList;
    this.statusStudyListRadi = StaticData.statusStudyListRadi;
    this.eClarityFee = StaticData.eClarityFee;
    this.month = StaticData.month;
    this.year = StaticData.year;
    this.assignmentRule = StaticData.assignmentRule;
    this.roleType = StaticData.roleType;
    this.visibilityRule = StaticData.visibilityRule;
    this.hideCentreName = StaticData.hideCentreName;
    this.templateType = StaticData.templateType;
    this.boroughStatus = StaticData.boroughStatus;
    this.sharingScope = StaticData.sharingScope;
    this.subjectTicket = StaticData.subjectTicket;
    this.userRole = StaticData.userRole;
    this.centerUser = StaticData.centerUser;
    this.makeItEditable = StaticData.makeItEditable;
    this.statusRadi = StaticData.statusRadi
  }
  sortLabelsAlphabetically(arr: { label: string; value: number }[]): { label: string; value: number }[] {
    return arr.slice().sort((a, b) => a.label.localeCompare(b.label));
  }
}
