import { Component } from '@angular/core';
import {
  Chart,
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  BarController,
  Tooltip,
  Legend,
  PieController,
  ArcElement
} from 'chart.js';
import { UserService } from 'src/app/_common/services/user.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {




  constructor(private userService: UserService) {
    Chart.register(
      LineElement,
      LineController,
      CategoryScale,
      LinearScale,
      PointElement,
      BarElement,
      BarController,
      Tooltip,
      Legend,
      PieController,
      ArcElement
    );
  }

  ngAfterViewInit() {
    this.createLineGraph();
    this.createBarGraph();
    this.createSalesLineGraph();
    this.createSalesPieChart();
    this.createBarGraphIndicator();
  }

  createLineGraph() {
    const lineGraphCtx = document.getElementById('linegraph') as HTMLCanvasElement;
    new Chart(lineGraphCtx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'Medicine Taken',
            data: [65, 59, 80, 81, 56, 55, 60, 69],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'Medicine Not Taken',
            data: [45, 40, 34, 30, 20, 18, 15, 12],
            fill: false,
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true
          }
        }
      }
    });
  }

  createBarGraph() {
    const barGraphCtx = document.getElementById('bargraph') as HTMLCanvasElement;
    new Chart(barGraphCtx, {
      type: 'bar',
      data: {
        labels: ['Govt', 'Private'],
        datasets: [{
          label: 'Patients In',
          data: [12, 19],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          legend: {
            display: true
          }
        }
      }
    });
  }


  createBarGraphIndicator() {
    const barGraphCtx = document.getElementById('barsgraph') as HTMLCanvasElement;
    new Chart(barGraphCtx, {
      type: 'bar',
      data: {
        labels: ['ACEI', 'P2Y2 receptor inhibitor', 'Statins'],
        datasets: [
          {
            label: '1st Quarter',
            data: [75, 90, 85],
            backgroundColor: 'blue'
          },
          {
            label: '2nd Quarter',
            data: [80, 85, 90],
            backgroundColor: 'orange'
          },
          {
            label: '3rd Quarter',
            data: [85, 80, 75],
            backgroundColor: 'gray'
          },
          {
            label: '4th Quarter',
            data: [90, 95, 100],
            backgroundColor: 'yellow'
          }
        ]
      },
      options: {
        responsive: true,
        scales: {
          x: {
            min: 0,
            max: 120,
            ticks: {
              stepSize: 20
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              autoSkip: false
            }
          }
        },
        plugins: {
          legend: {
            display: true
          }
        }
      }
    });
  }


  createSalesLineGraph() {
    const ctx = document.getElementById('salesLineGraph') as HTMLCanvasElement;
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [{
          label: 'Scale',
          data: [15000, 20000, 18000, 22000, 24000, 26000, 30000, 27500,],
          borderColor: 'rgb(54, 162, 235)',
          fill: false,
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true
          }
        }
      }
    });
  }

  createSalesPieChart() {
    const ctx = document.getElementById('salesPieChart') as HTMLCanvasElement;
    if (ctx) {
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ['Thiruvanathapuram', 'Kollam', 'Pathanamthitta'],
          datasets: [{
            label: 'Patients',
            data: [30000, 25000, 20000],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true
            }
          }
        }
      });
    } else {
      console.error('Canvas with id salesPieChart not found');
    }
  }


  ngOnInit() {

  }
}
