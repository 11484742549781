import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { StaticService } from './data/static.service';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AvatarModule } from 'primeng/avatar';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';;
// import { MessageService } from 'primeng/api';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BadgeModule } from 'primeng/badge';
import { ToolbarModule } from 'primeng/toolbar';

import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [CommonModule, ButtonModule, DropdownModule, AvatarModule, FormsModule, ToolbarModule, MenuModule, OverlayPanelModule, DialogModule, ReactiveFormsModule, MessagesModule, TieredMenuModule, BadgeModule, CheckboxModule, ToastModule],
  declarations: [FieldErrorComponent],
  exports: [FieldErrorComponent,
    CommonModule, FormsModule, ToolbarModule, DialogModule, ConfirmDialogModule, ToastModule],
  providers: [StaticService],
})
export class SharedModule { }
