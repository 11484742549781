<!-- <app-header></app-header>
<main>
  <app-sidebar></app-sidebar>
  <p-toast position="top-right" key="msgs" [showTransitionOptions]="'1000ms'" [style]="{width: '30%'}"></p-toast>
  <div class="router-outlet-background">
    <router-outlet></router-outlet>
  </div>
</main>
<app-footer></app-footer>
<p-confirmDialog></p-confirmDialog> -->

<app-header *ngIf="userService.isLoggedIn()"></app-header>
<main>
  <app-sidebar *ngIf="userService.isLoggedIn()"></app-sidebar>
  <p-toast position="top-right" key="msgs" [showTransitionOptions]="'1000ms'" [style]="{width: '30%'}"></p-toast>
  <div class="router-outlet-background">
    <router-outlet></router-outlet>
  </div>
</main>
<p-confirmDialog></p-confirmDialog>